import * as React from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import logoBlack from "../images/logo-dark.svg";

import { navigate } from "gatsby"
import axios from "axios"
//import for short url end

import { FIND_A_PROPERTY_PAGE_URL, VALUATION_PAGE_URL } from "../components/common/site/constants"

// the below async function wait till get data from strapi using graphql
const getPropurl = async (handler, crmid) => {
  let result = []
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data = JSON.stringify({
    "query": "query($crm_id:[String]) { properties(where: { crm_id: $crm_id}) { id, slug, search_type, department, crm_id } }",
    "variables": { "crm_id": [crmid] }
  });
  var config = {
    method: 'post',
    url: `${process.env.GATSBY_STRAPI_FEED_SRC}/graphql`,
    headers: {
      'Content-Type': 'application/json',
			'Authorization': `Bearer ${process.env.GATSBY_STRAPI_FEED_AUTH_TOKEN}`
    },
    data: data
  };
  const res = await axios(config);
  result[0] = JSON.stringify(res.data);
  handler(result);//update state var
}



const getNewPropurl = async (handler, crmid) => {
  let result_new = []
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data_new = JSON.stringify({
    "query": "query($development_id:[String]) { newDevelopments(where: { development_id: $development_id}) { id, slug, development_id } }",
    "variables": { "development_id": [crmid] }
  });
  var config = {
    method: 'post',
    url: `${process.env.GATSBY_STRAPI_FEED_SRC}/graphql`,
    headers: {
      'Content-Type': 'application/json',
			'Authorization': `Bearer ${process.env.GATSBY_STRAPI_FEED_AUTH_TOKEN}`
    },
    data: data_new
  };
  const res = await axios(config);
  result_new[0] = JSON.stringify(res.data);
  handler(result_new);//update state var
}

const NotFoundPage = (props) => {
  const [showtemplate, setShowtemplate] = React.useState(false);
  const [propdata, setPropdata] = React.useState('');
  const [newpropdata, setNewPropdata] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let regex = "\[0-9]{8}"; //you can modify the preg as per proj feed
    let news_path = "/news/"
    let sale_path = "for-sale"
    let rent_path = "for-rent"
    let sale_search_path = "/properties/for-sale/"
    let rent_search_path = "/properties/for-rent/"
    let new_development_path = "/new-homes/development/"
    let new_development_not_path = "/development-not-available/"

    if (props?.location?.pathname) {
      let crm_id = (props?.location?.pathname).match(regex);
      let news_path_contains = (props?.location?.pathname).match(news_path);
      let new_development_path_full = (props?.location?.pathname).match(new_development_path);

      let sale_path_full = (props?.location?.pathname).match(sale_path);
      let rent_path_full = (props?.location?.pathname).match(rent_path);

      let sale_search_path_full = (props?.location?.pathname).match(sale_search_path);
      let rent_search_path_full = (props?.location?.pathname).match(rent_search_path);

      let new_development_not_path_full = (props?.location?.pathname).match(new_development_not_path);
      
      if (typeof sale_search_path_full != "undefined" && sale_search_path_full != null && sale_search_path_full.length > 0) {
        setLoading(true);
        setShowtemplate(false);
        var url_render = (props?.location?.pathname).split('/')
        var slug_name = url_render[url_render.length-2]
        navigate(`/property/for-sale/in-`+slug_name+`/`)
      }
      if (typeof rent_search_path_full != "undefined" && rent_search_path_full != null && rent_search_path_full.length > 0) {
        setLoading(true);
        setShowtemplate(false);
        var url_render = (props?.location?.pathname).split('/')
        var slug_name = url_render[url_render.length-2]
        navigate(`/property/to-rent/in-`+slug_name+`/`)
      }
      if (typeof new_development_not_path_full != "undefined" && new_development_not_path_full != null && new_development_not_path_full.length > 0) {
        setLoading(true);
        setShowtemplate(false);
        navigate(`/property/new-homes/for-sale/`)
        localStorage.setItem('searchValue', '');
      }


      if(crm_id == null && news_path_contains == null && new_development_path_full == null && sale_search_path_full == null && rent_search_path_full == null && new_development_not_path_full == null) {
        setLoading(false);
        setShowtemplate(true);
      }
      if (typeof news_path_contains != "undefined" && news_path_contains != null && news_path_contains.length > 0) {
        setLoading(true);
        setShowtemplate(false);
        var path_news = props?.location?.pathname.replace(/^\/|\/$/g, '')
        path_news = path_news.split("/").pop()
        navigate(`/latest-property-news/${path_news}/`)
      }
      if (typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata) {
        getPropurl(setPropdata, crm_id[0]); // call the function to fetch data from strapi
      } else if (propdata) {
        let property_data = JSON.parse(propdata);
        let property_arr = property_data.data.properties;
        if (property_arr.length > 0) {
          setLoading(true)
          let property = property_arr[0];
          //set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
          if (property.department == 'residential') {
            if (property.crm_id) {
              if (property.search_type == 'sales') {
                navigate(`/property-for-sale/${property.slug}-${property.crm_id}/`, { replace: true });
              }
              else if (property.search_type == 'lettings') {
                navigate(`/property-to-rent/${property.slug}-${property.crm_id}/`, { replace: true });
              }
            }
          }
          if (property.department == 'new_developments') {
            if (property.crm_id) {
              if (property.search_type == 'sales') {
                navigate(`/new-home-for-sale/${property.slug}-${property.crm_id}/`, { replace: true });
              }
              else if (property.search_type == 'lettings') {
                navigate(`/new-homes-to-rent/${property.slug}-${property.crm_id}/`, { replace: true });
              }
            }
          }
        } else if(property_arr.length == 0) {
          if (typeof sale_path_full != "undefined" && sale_path_full != null && sale_path_full.length > 0) {
            navigate(`/property/for-sale/in-billingshurst-west-sussex/radius-30-miles/`)
            localStorage.setItem('searchValue', 'billingshurst-west-sussex');
          }
          else if (typeof rent_path_full != "undefined" && rent_path_full != null && rent_path_full.length > 0) {
            navigate(`/property/to-rent/in-billingshurst-west-sussex/radius-30-miles/`)
            localStorage.setItem('searchValue', 'billingshurst-west-sussex');
          }
          else {
            setLoading(false)
            setShowtemplate(true);// show 404 page template
          }
        } else {
          setLoading(false)
          setShowtemplate(true);// show 404 page template
        }
      }
    }
  }, [propdata]);



  React.useEffect(() => {
    let new_development_path = "/new-homes/development/"
    if (props?.location?.pathname) {
      let crm_id = (props?.location?.pathname).match(new_development_path);
      if (typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !newpropdata) {
        var dev_path_new = props?.location?.pathname.split("/")
        // dev_path_new = path_news.split("/")
        getNewPropurl(setNewPropdata, dev_path_new[3]); // call the function to fetch data from strapi
      } else if (newpropdata) {
        let property_data = JSON.parse(newpropdata);
        let property_arr = property_data.data.newDevelopments;
        if (property_arr.length > 0) {
          setLoading(true)
          let property = property_arr[0];
          //set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
          navigate(`/new-home-for-sale/${property.slug}-${property.id}/`, { replace: true });
        } else if(property_arr.length == 0) {
          setLoading(true);
          setShowtemplate(false);
          navigate(`/property/new-homes/for-sale/`)
          localStorage.setItem('searchValue', '');
        } else {
          setLoading(false)
          setShowtemplate(true);// show 404 page template
        }
      }
    }
  }, [newpropdata]);

  
  if (loading) return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
        </div>
      </div>
    </section>
  );

  return (
    <>
      {showtemplate &&
        <div className="wrapper error-details">
          <Header />
          <Seo title="Cubitt & West | Page Not Found" />
          <div className="error-page">
            <Container>
              <Row>
                <Col lg={12} className="main-banner content">
                  <h1>Oops! Page Cannot Be Found</h1>
                  <p>The page you are looking for may have moved or it might not exist anymore. Try searching with the menu at the top of the page or the using the buttons below.</p>
                  <div className="btn-wrap">
                    <Link class="btn btn-secondary" to={VALUATION_PAGE_URL.alias}>
                      <span>Selling or letting?</span>Book a valuation
                    </Link>
                    <Link class="btn" to={FIND_A_PROPERTY_PAGE_URL.alias}>
                      <span>Buying or renting?</span>Find a property
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      }
    </>

  )
}

export default NotFoundPage
