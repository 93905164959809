import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Container } from 'react-bootstrap';
import Img from 'gatsby-image';
import parse from 'html-react-parser';
// import { Parallax, Background } from 'react-parallax';
import ScrollAnimation from 'react-animate-on-scroll';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'
import $ from 'jquery'
import { navigate, useMatch } from "@reach/router"
import SearchResultInputBox from "../../predictive-search/SearchResultBox"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../../utils/property-search"
import GenerateLink from "../../common/site/generate-link"
import TrustPilotWidget from "../../TrustPilotWidget"
// Images
import Trustpilot from "../../../images/trustpilot.png"
import GoogleReviews from "../../../images/google-reviews.png"
import PartnerLogo1 from "../../../images/area-guide/arun-estates.svg"
import PartnerLogo2 from "../../../images/area-guide/logo-svg.svg"
import PartnerLogo3 from "../../../images/area-guide/ArunBrandBook.svg"
import PartnerLogo4 from "../../../images/area-guide/douglas.svg"
import PartnerLogo5 from "../../../images/area-guide/pittis.svg"
import PartnerLogo6 from "../../../images/area-guide/mortgage-white.svg"
import { useWindowSize } from '../../../hooks/winodw-size'
import Close from "../../../images/home-close.svg"
import "./AreaGuideBanner.scss"
import GoogleReviewCount from "../../GoogleReviewCount/GoogleReviewCount"
import { inViewOptions, inViewOptions3, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
// markup

const LoqateAddress = loadable(() => import('../../forms/elements/address-search-instant'))

const LandingBanner = (props) => {
  const [windowWidth] = useWindowSize();

  const [bannercta, setBannerCTA] = useState(true);
  const [valuation, setValuation] = useState(false);
  const [propertysearch, setPropertySearch] = useState(false);
  const [areaVal, setAreaVal] = useState('')
  const queryList = QueryList();

  function OpenValuation() {
    setBannerCTA(false)
    setValuation(true)
    setPropertySearch(false)

  }

  function OpenPropertySearch() {
    setBannerCTA(false)
    setValuation(false)
    setPropertySearch(true)

  }

  function CloseSearch() {
    setBannerCTA(true)
    setValuation(false)
    setPropertySearch(false)

  }


  // Valuation
  function onClickBuy(e) {
    var address = $("input[name=in_address]").val();
    var postcode = $("input[name=in_postcode]").val();
    var in_address_home = $("input[name=in_address_home]").val();
    var instant_stno = $("input[name=instant_stno]").val();
    var instant_street = $("input[name=instant_street]").val();
    var instant_district = $("input[name=instant_district]").val();
    var instant_post_town = $("input[name=instant_post_town]").val();
    var instant_county = $("input[name=instant_county]").val();
    var instant_postcode = $("input[name=instant_postcode]").val();
    var hometrack_add = $("input[name=hometrack_add]").val();
    localStorage.setItem('hometrack_add', hometrack_add)
    localStorage.setItem('instant-address-full', address)
    localStorage.setItem('instant-address', in_address_home)
    localStorage.setItem('instant-postcode', postcode)
    localStorage.setItem('instant-stno', instant_stno)
    localStorage.setItem('instant-street', instant_street)
    localStorage.setItem('instant-district', instant_district)
    localStorage.setItem('instant-post_town', instant_post_town)
    localStorage.setItem('instant-county', instant_county)
    localStorage.setItem('instant-postcode', instant_postcode)
    navigate(`/sell-your-property/property-valuation/instant-valuation`)
  }

  function onClickRent(e) {
    var address = $("input[name=in_address]").val();
    var postcode = $("input[name=in_postcode]").val();
    var in_address_home = $("input[name=in_address_home]").val();
    var instant_stno = $("input[name=instant_stno]").val();
    var instant_street = $("input[name=instant_street]").val();
    var instant_district = $("input[name=instant_district]").val();
    var instant_post_town = $("input[name=instant_post_town]").val();
    var instant_county = $("input[name=instant_county]").val();
    var instant_postcode = $("input[name=instant_postcode]").val();
    var hometrack_add = $("input[name=hometrack_add]").val();
    localStorage.setItem('hometrack_add', hometrack_add)
    localStorage.setItem('instant-address-full', address)
    localStorage.setItem('instant-address', in_address_home)
    localStorage.setItem('instant-postcode', postcode)
    localStorage.setItem('instant-stno', instant_stno)
    localStorage.setItem('instant-street', instant_street)
    localStorage.setItem('instant-district', instant_district)
    localStorage.setItem('instant-post_town', instant_post_town)
    localStorage.setItem('instant-county', instant_county)
    localStorage.setItem('instant-postcode', instant_postcode)
    navigate(`/sell-your-property/property-valuation/instant-valuation`)
  }
  // Valuation


  // Property Search 

  function onClickPropertyBuy(val) {
    let url = "/property/for-sale/";
    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase()

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      url += "in-billingshurst-west-sussex/radius-30-miles/"
    }

    localStorage.setItem('searchValue', searcValue ? searcValue : 'billingshurst-west-sussex');
    localStorage.setItem('searchValueFull', searcValue ? searcValue : 'billingshurst-west-sussex');
    localStorage.setItem('searchValueFullName', val ? val : 'Billingshurst, West Sussex');

    navigate(url);
  }
  function onClickPropertyRent(val) {
    let url = "/property/to-rent/";
    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase()

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      url += "in-billingshurst-west-sussex/radius-30-miles/"
    }

    localStorage.setItem('searchValue', searcValue ? searcValue : 'billingshurst-west-sussex');
    localStorage.setItem('searchValueFull', searcValue ? searcValue : 'billingshurst-west-sussex');
    localStorage.setItem('searchValueFullName', val ? val : 'Billingshurst, West Sussex');

    navigate(url);
  }


  // Property Search


  useEffect(() => {
    setBannerCTA(true)
    setValuation(false)
    setPropertySearch(false)
  }, []);



  const image_url = props.Banner_Image?.url;

  let processedImages = JSON.stringify({});
  if (props.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms;
  }



  return (
    <>
      <div className="main-banner area-guide-banner landing-page-banner components--AreaGuideDetails--AreaGuideBanner--LandingBanner slide-text-wrapper">
        {/* <Background className="main-banner-embed">
            <img src={props.Banner_Image.url} alt="fill murray" />
        </Background> */}

        <div className="main-banner-embed">

          {props.Banner_Image &&
            <GatsbyImage image={getImage(props.Banner_Image.url_sharp)} alt={props.Banner_Image.alternativeText} />
            // <div className="gatsby-image-wrapper">
            //   <ImageTransform
            //     imagesources={image_url}
            //     renderer="srcSet"
            //     imagename="articles.Banner_Image.details"
            //     attr={{ alt: '', class: '' }}
            //     imagetransformresult={processedImages}
            //     id={props.id}
            //   />
            // </div>
            // <Img fluid={props.Banner_Image.url_sharp.childImageSharp.fluid} alt={props.Banner_Image.alternativeText} />
          }

        </div>
        <div className="main-banner-content">
          <Container>
            <InView  {...inViewOptions}>
              {({ ref, inView }) => (
                <motion.div
                  className="banner-text"
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={contentItemStagger}

                >
                  <motion.div key={1} custom={1} variants={fadeInFromTop}>
                    {parse(props.Banner_Content)}
                  </motion.div>
                  {props.Banner_CTA_1_Label || props.Banner_CTA_2_Label ?
                    <div className="home-banner-search" id="home-banner-search">

                      {bannercta &&
                        <div className="btn-wrap" id="home-banner-search-cta">
                          <motion.div key={2} custom={2} variants={fadeInFromTop} className="btn-width-cta">
                            {props.Banner_CTA_1_Label === "Book a valuation" ?
                              <a href="javascript:void(0)" class="btn btn-secondary" onClick={() => OpenValuation()}>
                                {props.Banner_CTA_1_Label_Heading && <span>{props.Banner_CTA_1_Label_Heading}</span>}
                                {props.Banner_CTA_1_Label}
                              </a> :
                              props.Banner_CTA_1_Label === "Find a property" ?
                                <a href="javascript:void(0)" class="btn" onClick={() => OpenPropertySearch()}>
                                  {props.Banner_CTA_1_Label_Heading && <span>{props.Banner_CTA_1_Label_Heading}</span>}
                                  {props.Banner_CTA_1_Label}
                                </a> :
                                props.Banner_CTA_1_Label &&
                                <GenerateLink link={props.Banner_CTA_1_Link} class="btn btn-secondary">
                                  {props.Banner_CTA_1_Label_Heading && <span>{props.Banner_CTA_1_Label_Heading}</span>}
                                  {props.Banner_CTA_1_Label}
                                </GenerateLink>
                            }
                          </motion.div>
                          <motion.div key={3} custom={3} variants={fadeInFromTop} className="btn-width-cta">
                            {props.Banner_CTA_2_Label === "Find a property" ?
                              <a href="javascript:void(0)" class="btn" onClick={() => OpenPropertySearch()}>
                                {props.Banner_CTA_2_Label_Heading && <span>{props.Banner_CTA_2_Label_Heading}</span>}
                                {props.Banner_CTA_2_Label}
                              </a> :
                              props.Banner_CTA_2_Label === "Book a valuation" ?
                                <a href="javascript:void(0)" class="btn btn-secondary" onClick={() => OpenValuation()}>
                                  {props.Banner_CTA_2_Label_Heading && <span>{props.Banner_CTA_2_Label_Heading}</span>}
                                  {props.Banner_CTA_2_Label}
                                </a> :
                                props.Banner_CTA_2_Label &&
                                <GenerateLink link={props.Banner_CTA_2_Link} class="btn">
                                  {props.Banner_CTA_2_Label_Heading && <span>{props.Banner_CTA_2_Label_Heading}</span>}
                                  {props.Banner_CTA_2_Label}
                                </GenerateLink>
                            }
                          </motion.div>
                        </div>
                      }
                      {valuation &&
                        <ScrollAnimation animateIn="fadeInUp">
                          <div className="home-banner-search-valuation" id="home-banner-search-valuation">
                            <LoqateAddress />
                            <div className="btn-wrap">
                              <button class="btn btn-secondary" onClick={() => onClickBuy()}>
                                <span>I want to</span>Sell
                              </button>
                              <button class="btn btn-primary" onClick={() => onClickRent()}>
                                <span>I want to</span>Let
                              </button>
                              <img src={Close} className="close" alt="" onClick={() => CloseSearch()} />
                            </div>
                          </div>
                        </ScrollAnimation>
                      }
                      {propertysearch &&
                        <ScrollAnimation animateIn="fadeInUp">
                          <div className="home-banner-search-property" id="home-banner-search-property">
                            <div class="text form-group form-group">
                              <div class="custom-float">
                                <SearchResultInputBox
                                  value={areaVal !== 'billingshurst-west-sussex' ? hyphenToCaptilize(areaVal) : ''}
                                  setValue={(searchValue) => {
                                    // refine(removeSpecialChar(searchValue))
                                    setAreaVal(searchValue)
                                  }}
                                  placeHolder={`Enter town or area`}
                                  areaList={queryList.areaList}
                                  additionalclass="address address-search"
                                />
                              </div>
                            </div>
                            <div className="btn-wrap">
                              <button class="btn btn-secondary" onClick={() => onClickPropertyBuy(areaVal)}>
                                <span>I’d like to</span>Buy
                              </button>
                              <button class="btn" onClick={() => onClickPropertyRent(areaVal)}>
                                <span>I’d like to</span>Rent
                              </button>
                              <img src={Close} alt="" onClick={() => CloseSearch()} />
                            </div>

                          </div>
                        </ScrollAnimation>
                      }

                    </div> : ''}
                </motion.div>
              )}
            </InView>
            {props.Show_Reviews &&
              <InView  {...inViewOptions3}>
                {({ ref, inView }) => (
                  <motion.div
                    className="rating-block"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={contentItemStagger}

                  >
                    <motion.div key={4} custom={4} variants={fadeInFromTop} className="trustpilot-review">
                      <TrustPilotWidget />
                    </motion.div>
                    <div className="land-banner-review">
                    <motion.div key={5} custom={5} variants={fadeInFromTop}  className="google-review">
                      <GoogleReviewCount />
                      {/* <div className="review-logo">
                        <img src={GoogleReviews} alt="" width="74" />
                      </div>
                      <p>
                        <strong>4.5 out of 5</strong>
                        based on 64 reviews
                      </p> */}
                    </motion.div>
                    </div>
                  </motion.div>
                )}
              </InView>
            }


            {props.Show_Brands_Logo &&
              <InView  {...inViewOptions}>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={contentItemStagger}

                  >
                    <p className="content-text">{windowWidth <= 768 && 'Part of the Arun Estates family'} {windowWidth > 769 && 'The Arun Estates family'} </p>
                    <ul className="partner-logo">
                      <li className="partner-logo1">
                        <motion.div variants={contentItem}>
                          <img src={PartnerLogo1} alt="" />
                        </motion.div>
                      </li>
                      <li className="partner-logo2">
                        <motion.div variants={contentItem}><img src={PartnerLogo2} alt="" />
                        </motion.div>
                      </li>
                      <li className="partner-logo3">
                        <motion.div variants={contentItem}><img src={PartnerLogo3} alt="" />
                        </motion.div>
                      </li>
                      <li className="partner-logo4">
                        <motion.div variants={contentItem}><img src={PartnerLogo4} alt="" />
                        </motion.div>
                      </li>
                      <li className="partner-logo5">
                        <motion.div variants={contentItem}><img src={PartnerLogo5} alt="" />
                        </motion.div>
                      </li>
                      <li className="partner-logo6">
                        <motion.div variants={contentItem}><img src={PartnerLogo6} alt="" />
                        </motion.div>
                      </li>
                    </ul>
                  </motion.div>
                )}
              </InView>
            }

          </Container>
        </div>
      </div>
    </>
  )
}
export default LandingBanner