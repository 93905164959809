import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col, Form } from "react-bootstrap"
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget"



const TrustPilot = (props) => {

    const ref = useRef(null);
    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []); 

    return (
        <div className="trustpilot-reviews-list">
            <Container>
                <Row>
                    <Col lg={12}>
                        {/* <TrustpilotReviews
                        language="en"
                        culture="US"
                        theme="light"
                        width="100%"
                        height="100%"
                        stars="5"
                        template="539ad60defb9600b94d7df2c"
                        business="54f99d160000ff00057dde5c"
                        username="www.cubittandwest.co.uk"
                        /> */}
                        {/* <div class="trustpilot-widget" data-locale="en-GB" data-template-id="539adbd6dec7e10e686debee" data-businessunit-id="54f99d160000ff00057dde5c" data-style-height="500px" data-style-width="100%" data-theme="light" data-stars="5" data-review-languages="en">
                            <a href="https://uk.trustpilot.com/review/www.douglasallen.co.uk" target="_blank" rel="noopener">Trustpilot</a>
                        </div> */}
                        <div
                            ref={ref}
                            class="trustpilot-widget"
                            data-locale="en-GB"
                            data-template-id="539adbd6dec7e10e686debee"
                            data-businessunit-id="556c78ad0000ff00057fd7be"
                            data-style-height="1000px"
                            data-style-width="100%"
                            data-theme="light"
                            data-stars="4,5"
                            data-review-languages="en">
                            <a href="https://uk.trustpilot.com/review/www.cubittandwest.co.uk" target="_blank" rel="noopener">Trustpilot</a>
                        </div>

                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default TrustPilot;
