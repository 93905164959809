import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import parse from "html-react-parser"
import ScrollAnimation from 'react-animate-on-scroll';
import VideoImg from "../../../images/area-guide/news_376.jpg";
import VideoImg768 from "../../../images/area-guide/news_768.jpg";
import VideoImg1400 from "../../../images/area-guide/news_1400.jpg";
import VideoImg1600 from "../../../images/area-guide/news_1600.jpg";
import { useWindowSize } from '../../../hooks/winodw-size'
import PlayVideo from '../../../components/Play/CustomVideo';
import { NewsDate } from "../../common/site/functions"
import { LATEST_NEWS_PAGE_URL } from "../../common/site/constants"

import "./News.scss"
// markup
const News = (props) => {
  const [windowWidth] = useWindowSize();
  const [isPlay, setPlay] = useState(false);
  const [show, setShow] = useState(false);
  const showFilter = () => {
    setShow(!show);
  };

  var main_con = props.Content.substr(0, windowWidth < 1520 ? 420 : 480)

  return (
    <React.Fragment>
      <div className="news-wrap">
        <Container>
          <Row>
            <div className="row-box flex">
              <Col lg="7" className="pe-lg-0 ps-lg-0 xxl-8">
                <picture className="img-zoom about-block-img">
                  <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${props.URL}/`}>
                    <img src={props.Tile_Image.url} alt={`${props.Tile_Image && props.Tile_Image.alternativeText ? props.Tile_Image.alternativeText : props.Title} - Cubitt & West`}  />
                  </Link>
                  {props.Video_URL &&
                    <PlayVideo url={props.Video_URL} />
                  }
                </picture>
              </Col>
              <Col lg="5" className="ps-lg-0 xxl-4">
                <div className="news-content">
                  <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${props.URL}/`}>
                    <h3>{props.Title}</h3>
                  </Link>
                  <span className="date-text">{NewsDate(props.Date)}</span>
                  <p className="news-land-main-content">{parse(main_con)}...</p>
                  <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${props.URL}/`} className="btn">
                    {windowWidth <= 768 && 'Find out more'}
                    {windowWidth > 769 && ' Read More'}
                    <i className="icon-arrow"></i></Link>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default News