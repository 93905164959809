
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function BranchStructuredData({ property, pageurl }) {
  const cloud_url = process.env.GATSBY_CLOUD_URL;
  const site_url = process.env.GATSBY_SITE_URL;
  var ldJson = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "url": site_url   + '/branchfinder',
    "name": "Branch Locator | Cubitt & West",
    "description": "To speed things along we have created a great branch locator to find exactly what you are looking for in your area!"
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default BranchStructuredData
