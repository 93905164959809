import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Select from "react-dropdown-select";
import parse from "html-react-parser"
import ScrollAnimation from 'react-animate-on-scroll';
import GenerateLink from "../common/site/generate-link"
import TrustPilotWidget from "../TrustPilotWidget"

import Trustpilot from "../../images/trustpilot.png"
import GoogleReviews from "../../images/google-reviews.png"
import GoogleReviewCount from "../GoogleReviewCount/GoogleReviewCount"

import "../Valuation/ValuationForm/ValuationForm.scss"
// markup
const BGImageLanding = (props) => {
  return (
    <React.Fragment>
      <section className="main-banner valuation-page new-homes-landing-page" style={{ background: "url(" + props.page.Banner_Image.url + ")" }}>
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <Container>
          <Row>
            <Col md='12' className="head">
              {parse(props.page.Banner_Content)}
            </Col>
          </Row>
          {props.module && props.module.map((module, i) => {
            return (
              <Row>
                {module.Add_New_Column && module.Add_New_Column.map((item, index) => {
                  return (
                    <Col lg={5}>
                      <div className="block">
                        {parse(item.Content)}
                        <GenerateLink class={`${index == 0 ? 'btn btn-secondary' : 'btn'}`} link={item.CTA_Link}>{item.CTA_Label}</GenerateLink>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            )
          })}
          <div className="rating-block">
            <div className='trustpilot-review'>
              <TrustPilotWidget />
            </div>

            <div className='google-review'>
              <GoogleReviewCount />
              {/* <div className="review-logo">
                 <img src={GoogleReviews} alt="" width="74"/>
               </div>
               <p>
                 <strong>4.5 out of 5</strong>
                 based on 64 reviews
               </p> */}
            </div>
          </div>
        </Container>
        </ScrollAnimation>
      </section>
    </React.Fragment >
  )
}
export default BGImageLanding