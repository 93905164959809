import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { navigate, useMatch } from "@reach/router"
import axios from "axios";

import Select from "react-dropdown-select";
import parse from "html-react-parser"
import Img from 'gatsby-image';
import $ from "jquery"
import loadable from '@loadable/component'
import Img01 from "../../../images/valuation/call-us01.png"
import ScrollAnimation from 'react-animate-on-scroll';
import "./ValuationForm.scss"
import TrustPilotWidget from "../../TrustPilotWidget"

import HomeVisitForm from "../../forms/home-visit-valuation"
import InstantForm from "../../forms/instant-valuation"
import HomeTrackResult from "./ValuationHomeTrackResult"

import { CONTACT_PAGE_URL, FIND_A_PROPERTY_PAGE_URL } from "../../common/site/constants"

import Trustpilot from "../../../images/trustpilot.png"
import GoogleReviews from "../../../images/google-reviews.png"
// markup
import GoogleReviewCount from "../../GoogleReviewCount/GoogleReviewCount"

const Address = loadable(() => import('../../forms/elements/address-search'))
const AddressInstant = loadable(() => import('../../forms/elements/address-search-instant'))

const ValuationForm = (props) => {

  const [homevisitform, setHomeVisitForm] = useState(false);
  const [homevisitformresult, setHomeVisitFormResult] = useState(false);
  const [instantform, setInstantForm] = useState(false);
  const [instantformresult, setInstantFormResult] = useState(false);
  const [landing, setLanding] = useState(true);
  const [error, setError] = useState(false);
  const [errorin, setErrorIn] = useState(false);
  const [address, setAddress] = useState('');

  const [home_stno, setHomeStNo] = useState('');
  const [home_street, setHomeStreet] = useState('');
  const [home_district, setHomeDistrict] = useState('');
  const [home_post_town, setHomePostTown] = useState('');
  const [home_county, setHomeCounty] = useState('');
  const [home_postcode, setHomePostcode] = useState('');

  const [bedroom, setBedroom] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [ptype, setPType] = useState('');
  const [Homeaddress, setHomeAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [type, setType] = useState('');

  function OpenForm(val) {
    var address = $("input[name=address]").val();
    var home_stno = $("input[name=home_stno]").val();
    var home_street = $("input[name=home_street]").val();
    var home_district = $("input[name=home_district]").val();
    var home_post_town = $("input[name=home_post_town]").val();
    var home_county = $("input[name=home_county]").val();
    var home_postcode = $("input[name=home_postcode]").val();
    if (address && home_postcode) {

      localStorage.setItem('instant-address-full', address)
      localStorage.setItem('instant-address', address)
      localStorage.setItem('instant-stno', home_stno)
      localStorage.setItem('instant-street', home_street)
      localStorage.setItem('instant-district', home_district)
      localStorage.setItem('instant-post_town', home_post_town)
      localStorage.setItem('instant-county', home_county)
      localStorage.setItem('instant-postcode', home_postcode)

      localStorage.setItem('home-visit-type-val', val)
      navigate(`/sell-your-property/property-valuation/home-visit-valuation`)
    }
    else {
      setError(true)
    }
    // if (localStorage) {
    //   localStorage.removeItem('instant-address')
    //   localStorage.removeItem('instant-postcode')
    // }
  }

  function OpenInstant() {
    var address = $("input[name=in_address]").val();
    var postcode = $("input[name=in_postcode]").val();
    var in_address_home = $("input[name=in_address_home]").val();
    var instant_stno = $("input[name=instant_stno]").val();
    var instant_street = $("input[name=instant_street]").val();
    var instant_district = $("input[name=instant_district]").val();
    var instant_post_town = $("input[name=instant_post_town]").val();
    var instant_county = $("input[name=instant_county]").val();
    var instant_postcode = $("input[name=instant_postcode]").val();
    var hometrack_add = $("input[name=hometrack_add]").val();
    if (address && instant_postcode) {

      localStorage.setItem('instant-address-full', address)
      localStorage.setItem('instant-address', address)
      localStorage.setItem('instant-stno', instant_stno)
      localStorage.setItem('instant-street', instant_street)
      localStorage.setItem('instant-district', instant_district)
      localStorage.setItem('instant-post_town', instant_post_town)
      localStorage.setItem('instant-county', instant_county)
      localStorage.setItem('instant-postcode', instant_postcode)
      localStorage.setItem('hometrack_add', hometrack_add)
      navigate(`/sell-your-property/property-valuation/instant-valuation`)

      setHomeVisitForm(false)
      setInstantForm(true)
      setLanding(false)
      setErrorIn(false)
      setHomeAddress(in_address_home)
      setAddress(address)

      setHomeStNo(instant_stno)
      setHomeStreet(instant_street)
      setHomeDistrict(instant_district)
      setHomePostTown(instant_post_town)
      setHomeCounty(instant_county)
      setHomePostcode(instant_postcode)

      setPostcode(instant_postcode)
      setInstantFormResult(false)
      setHomeVisitFormResult(false)
    }
    else {
      setErrorIn(true)
    }
    // if (localStorage) {
    //   localStorage.removeItem('instant-address')
    //   localStorage.removeItem('instant-postcode')
    // }
  }

  return (
    <React.Fragment>
      <section className="valuation-page" style={{ background: "url(" + props.page.Banner_Image.url + ")" }}>
        <div className="page">
          <div className="val-main-landing" id="valuation-main">
            <Container>
              <Row>
                <Col md='12' >
                  {parse(props.page.Banner_Content)}
                </Col>
              </Row>
              {props.module && props.module.map((module, i) => {
                return (
                  <Row>
                     <Col lg={5}>
                      <div className="valuation-block">
                        {parse(module.Instant_Valuation)}
                        <div className="form">
                          {errorin &&
                            <div className="alert-danger">
                              Please select a full address from the list
                            </div>
                          }
                          <AddressInstant />
                          <div className="btn-wrap instant-val">
                            <button className="btn btn-primary cta-1" onClick={() => OpenInstant()}>Get an approximate valuation</button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="valuation-block instant-valuation-form">
                        {parse(module.Home_Visit_Valuation)}
                        <div className="form">
                          {error &&
                            <div className="alert-danger">
                              Please select a full address from the list
                            </div>
                          }
                          <Address />
                          <div className="btn-wrap home-visit-val">
                            <button className="btn btn-secondary cta-1" onClick={() => OpenForm('sell')}>I want to sell</button>
                            <button className="btn btn-secondary cta-2" onClick={() => OpenForm('let')}>I want to let</button>
                          </div>
                        </div>
                      </div>
                    </Col> 
                  </Row>
                )
              })}
              <div className="main-banner">
                <div className="rating-block">
                  <div className='trustpilot-review'>
                    <TrustPilotWidget />
                  </div>

                  <div className='google-review'>
                    <GoogleReviewCount />
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment >
  )
}
export default ValuationForm